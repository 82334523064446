export default {
  pagename: "首頁",
  landing: {
    title: "加速您的企業成長",
    subtitle: "雷弗資訊為您打造應用程式，量身定制",
  },
  service: {
    title: "服務",
    features: {
      website_design: {
        title: "網頁設計",
        description: "公司網頁、活動網頁、響應式設計及伺服器代管",
      },
      application_development: {
        title: "應用程式開發",
        description:
          "訂單管理、倉儲管理、出勤系統⋯ 在原有系統生態下加速您的作業流程",
      },
      customized_application: {
        title: "客製化應用",
        description: "內部系統整合、硬體API串接、訂單管理及資源規劃系統開發",
      },
    },
  },
  about: {
    title: "關於",
    descripiton: [
      "雷弗資訊創立於 2019 年 3 月，由一群熱血的軟體工程師所組成，我們熱愛並擁抱新的技術，相信不斷的改變才能造就不凡。",
      "我們致力於網站系統的開發，幫助傳統企業無紙化，並提供比套裝軟體、模板網站更客製化的功能。",
    ],
  },
  contact: {
    title: "聯絡我們",
    form: {
      name: "名字",
      email: "電子信箱",
      message: "留言",
    },
    error: {
      name: "請輸入您的名字",
      email: "請輸入您的電子信箱",
      message: "請留言",
    },
    button: {
      submit: "聯絡我們",
      submitSuccess: "發送成功",
      submitError: "發送失敗",
    },
    information: {
      address: "105台北市松山區民生東路三段156號9樓",
      location: "105台北市松山區民生東路三段156號9樓",
      email: "info@ravelinfo.com",
    },
  },
};
